import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faFlag, faCertificate, faSignIn } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { useAuth0 } from "../../context/react-auth0-spa";
import useStations from "../../utils/hooks/useStations";

const Neonicotinoids = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const h1Text = "NEWA | Network for Environment and Weather Applications";
  const title = "Neonicotinoids Risk Assessment and Certification";
  const { station } = useStations();

  // Pale blue box for the links
  const cardStyle = "w-2/3 md:w-48 h-32 md:h-48 border-radius-sm border-2 border-solid border-blue-200 bg-white shadow-md focus:shadow-xxl focus:scale-105 hover:shadow-xxl transition-all duration-200 flex flex-col no-underline shadow-md hover:shadow-xl transform hover:scale-105";
  const iconContainerStyle = "h-1/3 bg-blue-100 text-blue-900 flex items-center justify-center opacity-90";
  const textContainerStyle = "h-2/3 p-4 flex items-center justify-center text-blue-800";


  return (
    <Layout station={station} h1Text={h1Text}>
      <Seo title={title}/>
      <div className="container mx-auto container-article xl:pt-6 lg:px-4 xl:px-12 pb-6 md:pb-8 lg:pb-10">
        <h2 className="text-center">{title}</h2>
        <section id="links"
                 className="text-center p-6 flex flex-col items-center md:flex-row md:flex-wrap md:justify-center gap-6">
          {isAuthenticated ? (
            <>
              <a href="/seed-damage-report" className={cardStyle}>
                <div className={iconContainerStyle}><FontAwesomeIcon icon={faArrowRightToBracket}/></div>
                <div className={textContainerStyle}>Submit a seed damage report</div>
              </a>
              <a href="/seedcorn-maggot" className={cardStyle}>
                <div className={iconContainerStyle}><FontAwesomeIcon icon={faFlag}/></div>
                <div className={textContainerStyle}>Evaluate seedcorn maggot risk</div>
              </a>
              <a href="https://pmepcourses.cce.cornell.edu/product?catalog=1740168796mFCRw"
                 className={cardStyle}>
                <div className={iconContainerStyle}><FontAwesomeIcon icon={faCertificate}/></div>
                <div className={textContainerStyle}>Complete a neonicotinoids certification course</div>
              </a>
            </>
          ) : (
            <>
              <a href="#" onClick={(e) => {
                e.preventDefault();
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.pathname,
                  }
                });
              }} className={cardStyle}>
                <div className={iconContainerStyle}><FontAwesomeIcon icon={faSignIn} /></div>
                <div className={textContainerStyle}>Sign In or Create an Account</div>
              </a>
            </>
          )}
        </section>

      </div>
    </Layout>
  );
};

export default Neonicotinoids;
